$(document).ready(function() {
  var
    $body = $('body'),
    cookieAcceptLinkText = $body.data('cookiesLinkAcceptText'),
    cookieLinkText = $body.data('cookiesLinkText'),
    cookieMessage = $body.data('cookiesMessage'),
    cookiePolicyUrl = $body.data('cookiesPolicyUrl'),
    cookiePosition = 'bottom' === $body.data('cookiesPosition'),
    country = $body.data('country') ? $body.data('country'): 'fr'
  ;

  $.cookieCuttr({
    cookieAnalytics: false,
    cookieMessage: cookieMessage + ('fr' === country ? '&nbsp;<a class="cc-cookie-more" target="_blank" href="{{cookiePolicyLink}}">' + cookieLinkText + '</a>' : ''),
    cookieAcceptButtonText: cookieAcceptLinkText,
    cookiePolicyLink: cookiePolicyUrl,
    cookieNotificationLocationBottom: cookiePosition
  });

  /*
   * JQUERY VALIDATE.
   * - Javascript form validation before submit.
   */
  if ('function' === typeof $.fn.validate) {
    /*
     * Configure jquery validate for Bootstrap 3.
     */
    $.validator.setDefaults({
      highlight(element) {
        // Add the .has-error class to the form-group.
        $(element).closest('.form-group').addClass('has-error');
      },
      unhighlight(element) {
        // Remove the .has-error class from the form-group.
        $(element).closest('.form-group').removeClass('has-error');
      },
      errorElement: 'span',
      errorClass: 'help-block',
      errorPlacement(error, element) {
        // Handle the error placement for checkbox & radio.
        if (element.parent('.input-group').length || 'checkbox' === element.prop('type')  || 'radio' === element.prop('type')) {
          error.appendTo(element.parent());
        } else {
          error.insertAfter(element);
        }
      }
    });

    // Add a polyfill method to test custom regexp against user input.
    $.validator.addMethod('cemail', function(value, element, regexp)  {
      regexp = new RegExp(/\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b/i);

      return this.optional(element) || regexp.test(value);
    }, 'Please enter a valid email address');

    /*
     * Enable jquery-validate for all matching (not just the first) using a each() closure in case there's two form on
     * the same page, eg. sidebar and footer.
     */
    $('.js-contact-form, .js-newsletter-form').each(function(i, e) {
      $(e).validate({
        rules: {
          'front_message_type[email]': {
            cemail: true,
            email: false,
          },
          'front_newsletter_type[email]': {
            cemail: true,
            email: false,
          }
        }
      });
    });
  }

  /**
   * MATCH HEIGHT.
   * - Make sure Bootstrap 3 grid does not explode because of various thumbnail/col-* heights.
   */
  $('.match-height .thumbnail.grid').not('.file-icon').matchHeight({
    byRow: false
  });
  $('.js-match-height .card').matchHeight();

  $('.js-buckle--spokesperson').on('showing.jq.offie', '[data-toggle="offie"]', function(event) {
    var
      PUSHER_CLASS = 'js-gallery-pusher',
      $eventTarget = $(event.target),
      $buckleElement = $eventTarget.closest('.js-buckle--spokesperson')
    ;

    var $pusher = $eventTarget.find('.' + PUSHER_CLASS);

    if (0 === $eventTarget.length) {
      $eventTarget.append('<div class="' + PUSHER_CLASS + '" />');
    }

    var $offieTarget = event.offieDropdown.getTarget();

    $pusher.css({height: $offieTarget.outerHeight()});

    $buckleElement.data('relatedOffieDropdown', event.offieDropdown);

    // Fetch content of the folder.
    $buckleElement.data('buckle').fetch({});
  });

  $('.js-buckle--spokesperson').on('hiding.jq.offie', '[data-toggle="offie"]', function(event) {
    $(event.target).find('.js-gallery-pusher').css({height: 0});
  });

  $('.js-buckle--spokesperson').on('ajaxComplete.jq.buckle', function(event) {
    var
      $eventTarget = $(event.target),
      $offieTarget = $eventTarget.data('relatedOffieDropdown') ? $eventTarget.data('relatedOffieDropdown').getTarget() : null
    ;

    if ($offieTarget) {
      $eventTarget
        .find('.js-gallery-pusher')
        .css({height: $offieTarget.outerHeight()})
      ;
    }
  });

  $('.js-buckle--assets').on('showing.jq.offie', '[data-toggle="offie"]', function(event) {
    $(event.target).closest('.js-buckle--folder').data('buckle').fetch({});
  });

  $('.js-buckle--assets').on('ajaxComplete.jq.buckle', function(event) {
    var $element = event.buckle.getElement();

    // Update the grid.
    MatchHeightHelper.update(
      event.buckle.getContainer()[0],
      true,
      '.js-match-height .card'
    );

    // Enable any child buckle component (folders).
    $element.find('[data-toggle="buckle"]').buckle();

    // Enable any offie dropdown component (folders).
    $element.find('[data-toggle="offie"]').offie();
  });

  $('.js-buckle--spokesperson').on('ajaxComplete.jq.buckle', function(event) {
    // Update the grid.
    MatchHeightHelper.update(
      event.buckle.getContainer()[0],
      true,
      '.js-match-height .card'
    );
  });

  $body.append('<div class="backdrop"></div>');

  $('.navbar-content').on('showing.jq.offie', function() {
    $body.addClass('on');
    $('.backdrop').addClass('on');
    $(this).closest('.navbar--clientheader').addClass('on');
  });

  $('.navbar-content').on('hiding.jq.offie', function() {
    $body.removeClass('on');
    $('.backdrop').removeClass('on');
    $(this).closest('.navbar--clientheader').removeClass('on');
  });

  // Didomi - Display Banner
  let didomiBtn = document.getElementById('didomi-banner-btn') ?? null;
  if (didomiBtn != null) {
    didomiBtn.addEventListener('click', function() {
      window.Didomi?.preferences.show();
    });
  }
});
